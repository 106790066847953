import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Api } from '../../services/Api';

import formatCity from "./formatCity";


export function* watchFetchJourney() {
    yield takeEvery('FETCH_JOURNEY', updateJourney);
}

function* updateJourney(action) {

    const response = yield call(Api.getJourneyById, action.payload);
    try {
        const journey = yield formatAddresses(response.data);
        console.log(journey);
        yield put({ type: 'UPDATE_JOURNEY', payload: { data: journey, ...response } });

    } catch (err) {
        console.log(err);
        yield put({ type: 'UPDATE_JOURNEY', payload: { status: 500 } })
    }
}


const formatAddresses = async journey => {

    let stages = [];
    for (let key in journey) {
        if (key.substr(key.length - 5) === "Stage") {
            stages.push(journey[key]);
        }
    }
    if (stages[0]) {
        let growerAddress = await formatCity(stages[0].latitude, stages[0].longitude);
        journey.seedGrowingStage.address = growerAddress
    }
    if (stages[1]) {
        let farmerAddress = await formatCity(stages[1].latitude, stages[1].longitude);
        journey.seedPlantingStage.address = farmerAddress
    }
    if (stages[2]) {
        let elevatorAddress = await formatCity(stages[2].latitude, stages[2].longitude);
        journey.elevatorStage.address = elevatorAddress
    }
    if (stages[3]) {
        let manufactureAddress = await formatCity(stages[3].latitudeArrivedAt, stages[3].longitudeArrivedAt);
        journey.manufactureFoodStage.address = manufactureAddress
    }
    return journey;
}