import React from "react";
import bioData from "../../../resources/mockBioData.json";
import moment from "moment";

export const timelineData = (state = {}, action) => {
    switch (action.type) {
        case "UPDATE_JOURNEY":

            const response = action.payload;

            if (!(response.status >= 200 && response.status < 300)) {
                return null;
            }

            const data = response.data;

            const deliveries = data.deliveries;
            let stages = [];
            for (let key in data) {
                if (key.substr(key.length - 5) === "Stage") {
                    stages.push(data[key]);
                }
            }
            const isOrganic = stages[3] && stages[3].chemReason === "" ? true : false;
            let step = parseInt(data.step);


            const timelineData = [
                ...step && step >= 0 ? [{
                    possessor: `Soybean Variety: ${stages[0].variety}`,
                    description: `The very start of the tofu journey is the soybean! Developed by Dr Istvan Rajcan from Ontario Agricultural College (OAC) at the University of Guelph, the variety is registered for sale in Canada by CFIA (Canadian Food Inspection Agency).​ \n`,
                    fact: ' OAC Kent was awarded the prestigious “Seed of the Year” award in 2008 and still remains one of the most popular food grade soybean imported into Japan!',
                    state: "",
                    action: "",
                    address: "Location: Guelph, Ontario",
                    bioLink: 'https://files.secan.com/s3fs-public/OAC%20Kent%20TB_May%202018_0.pdf',
                    lng: -80.228674,
                    lat: 43.533076,
                    bio: "Ontario Agricultural College has been working since 1999 to breed soybeans with highly marketable traits. The overarching goal of the breeding program is to develop superior soybean cultivars and germplasm." +

                        "The work includes modified oils for emerging food, industrial, cosmetic, and pharmaceutical markets, as well as examining the impact of environment and genotype on isoflavone and other seed components. ",
                    image: require("../../../resources/images/timeline/variety.png"),
                    icon_path: require("../../../resources/images/information.svg")
                }] : [],
                ...step && step >= 1 ? [{
                    possessor: `Retailer: ${stages[0].seedBreeder.name}`,
                    state: "",
                    action: "It All Starts with Certified Seed",
                    address: `Location: ${stages[0].address}`,
                    timestamp: `Package Date: ${moment(stages[0].dateSold).format(`Do MMM YYYY`)}`,
                    description: '',
                    descriptionLink: `The first step of your tofu's journey begins with Canada’s seed certification system and the production of certified OAC Kent seed. Canada’s seed certification system ensures that high-quality seed is produced that is true-to-variety and is pure. To learn more about seed certification,`,
                    descriptionSecondary: `Your package of tofu can be traced to certified OAC Kent seed produced by ${stages[0].seedBreeder.name}`,
                    lng: stages[0].longitude,
                    lat: stages[0].latitude,
                    bio: bioData[stages[0].seedBreeder.name] || " ",
                    icon_path: require("../../../resources/images/food_states/seeds.svg"),
                    image: require("../../../resources/images/timeline/seed_grower.png")
                }] : [],
                ...step && step >= 2 ? [{
                    possessor: `Transporter Specified: ${deliveries[0].transporter}`,
                    state: "",
                    action: "Transit To Farm",
                    address: "",
                    timestamp: `Date: ${moment(deliveries[0].datePickedUp).format(`Do MMM YYYY`)}`,
                    description: `The seed is in transit to the farmer for spring planting`,
                    icon_path: require("../../../resources/images/food_states/delivery_truck.svg")
                }] : [],
                ...step && step >= 5 ? [{
                    possessor: ` Producer: ${stages[1].farmer.name}`,
                    state: "",
                    action: "Identity Preserved (IP) Program",
                    address: `Location: ${stages[1].address}`,
                    description: `An Identity Preserved (IP) program is used to ensure that only ${stages[0].variety} make it into your tofu. The tofu maker wants consistency in their supply of soybeans and has perfected their recipe using ${stages[0].variety}.`,
                    descriptionSecondary: `${stages[1].farmer.name} has taken great care to ensure that your soybeans are kept separate from all other soybeans to ensure full traceability back to the certified ${stages[0].variety} seed planted.`,
                    bio: bioData[stages[1].farmer.name] || "No bio provided.",
                    lng: stages[1].longitude,
                    lat: stages[1].latitude,
                    certifiedSeed: true,
                    icon_path: require("../../../resources/images/food_states/farm.svg"),
                    image: require("../../../resources/images/timeline/soy.png")
                }] : [],
                ...step && step >= 7 ? [{
                    possessor: `Transporter Specified: ${deliveries[1].transporter}`, //not good to specify farmer before the step has even commenced, just placeholder for now
                    state: "",
                    action: "Transit to Elevator",
                    address: "",
                    description: `The truck that the soybeans were transferred in was cleaned and inspected to ensure no contaminates were introduced.`,
                    icon_path: require("../../../resources/images/food_states/delivery_truck.svg")
                }] : [],
                ...step && step >= 9 ? [{
                    possessor: `Buyer: ${stages[2].elevator.name}`,
                    state: "",
                    action: "Arrival at Grain Elevator",
                    address: `Location: ${stages[2].address}`,
                    descriptionSecondary: `Bin: ${stages[2].bin} `,
                    lng: stages[2].longitude,
                    lat: stages[2].latitude,
                    video: "https://www.youtube.com/embed/24t-mKsOrJQ",
                    bio: bioData[stages[2].elevator.name] || "No bio provided.",
                    moisture: `Moisture: ${stages[2].moistureContent}%`,
                    grade: `Grade: OAC Kent`,
                    protein: `Protein: 44.5%`,
                    oil: `Oil: 20.6%`,
                    gmo: `GMO: Negative`,
                    gmoFree: true,
                    icon_path: require("../../../resources/images/food_states/silo.svg"),
                    image: require("../../../resources/images/timeline/elevator.png"),
                    // timestamp: `Arrival: ${moment(stages[2].dateStored).format(`Do MMM YYYY`)}`
                }] : [],
                ...step && step >= 11 ? [{
                    possessor: `Transporter Specified: ${deliveries[2].transporter}`,
                    state: "",
                    action: `Transit to Food Manufacturer`,
                    address: "",
                    timestamp: ` Departure: ${moment(deliveries[2].datePickedUp).format(`Do MMM YYYY`)}`,
                    description: `It’s almost tofu making time!`,
                    icon_path: require("../../../resources/images/food_states/delivery_truck.svg")
                }] : [],
                ...step && step >= 12 ? [{
                    possessor: `Buyer: ${stages[3].manufacturer.name}`,
                    state: "",
                    action: `Arrival at Food Manufacturer`,
                    address: `Location: ${stages[3].address}`,
                    timestamp: `Arrival: ${moment(deliveries[2].dateDroppedOff).format('Do MMM YYYY')}`,
                    icon_path: require("../../../resources/images/food_states/conveyor.svg"),
                    image: require("../../../resources/images/timeline/processor.png"),
                    lng: stages[3].longitudeArrivedAt,
                    lat: stages[3].latitudeArrivedAt,
                    bio: "Ying Ying Soy Food is a small family-owned and operated artisan tofu producer, who have been proudly serving Canadians with the cleanest and best tasting tofu products since 2001. Their tofu finds its roots in its 2000-year-old tradition and processing technique, by using the original coagulant, Nigari, and hand-coagulate and hand-make our tofu in small batches in the traditional way. ​\n" +
                        "\n" +
                        " ​\n" +
                        "\n" +
                        "The final products are also all hand-cut and wok cooked to perfection without using any chemical additives. Furthermore, we only use Certified Organic Soybeans grown in Ontario which are Non-GMO.",
                    logo: require('../../../components/history/CompanyIcons/ying_ying.png')
                }] : [],
                ...step && step >= 13 ? [{
                    possessor: stages[3].manufacturer.name,
                    state: "",
                    action: "Tofu Production",
                    timestamp: `Date: ${moment(stages[3].dateManufactured).format('Do MMM YYYY')}`,
                    grade: `Batch: Gourmet Tofu Sweet & Spicy`,
                    protein: `Units: 219`,
                    video2: "http://www.yingyingsoyfood.ca/links.html",
                    icon_path: require("../../../resources/images/food_states/processed_food.svg"),
                    image: require("../../../resources/images/timeline/tofu.png")
                }] : [],
                ...step && step >= 13 ? [{
                    possessor: 'Logistics: Fresh Spoke',
                    state: "",
                    timestamp: `Departure: ${moment(deliveries[3].datePickedUp).format('Do MMM YYYY')}`,
                    address: `Pick Up: ${stages[3].address}`,
                    action: `Transit To Markets`,
                    lng: -79.7160152,
                    lat: 44.3817176,
                    image: require("../../../resources/images/timeline/fresh-spoke.png"),
                    bio: 'FreshSpoke is an online marketplace that uses a set of simple yet powerful tools to connect local food producers with wholesale food buyers through a mobile app.​\n' +
                        '\n' +
                        '​\n' +
                        '\n' +
                        'FreshSpoke\'s shared delivery system leverages the excess capacity of commercial drivers - including our own food producers - traveling the roads everyday. We give them the opportunity to make extra cash by delivering local food, while giving buyers a convenient and affordable way to get fresh quality food delivered to their door from producers they know and trust.',
                    icon_path: require("../../../resources/images/food_states/delivery_truck.svg")
                }] : [],
                ...step && step >= 13 ? [{
                    state: "",
                    action: "The Final Journey!",
                    description: `With the soybean journey commencing during Spring 2018 in Middlesex County (Southwestern Ontario), it has finally made its way to the final destination. FreshSpoke has distributed tofu throughout multiple markets in the Ontario Greater Golden Horseshoe region.​`,
                    icon_path: require("../../../resources/images/food_states/groceries.svg"),
                    image: require("../../../resources/images/timeline/retailer.png")
                }] : [],
            ];

            return timelineData;
    }
    return state;
};
