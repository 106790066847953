export const exists = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_JOURNEY':
            const response = action.payload;
            let exists = false;
            
            if(response.status >= 200 && response.status < 300 ) {
                exists = true;
            };
            return exists;
    }
    return state;
};