import Geocode from 'react-geocode';

Geocode.setApiKey("AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo");

export default async (lat, lng) => {
    let address = {};
    try {
        const response = await Geocode.fromLatLng(lat, lng)

        const res = response.results[0];
        res.address_components.map(i => {
            if (i.types[0] === 'administrative_area_level_1') {
                address.province = i.short_name;
            }
            else if (!address.city && i.types.some(i => ['locality', 'neighborhood', 'sublocality'].includes(i))) {
                address.city = i.long_name;
            }
        })
    
        return address ? `${address.city}, ${address.province}` : address
    }
    catch(e) {
        return address;
    }
    
}