import axios from 'axios';

const sampleResponse = require('./sample.response.json');

const localServer = 'http://localhost:3030'
const proxy = 'https://cors-anywhere.herokuapp.com/'

const api = axios.create({
  baseURL: `https://wellspring.mybluemix.net`
  // baseURL: 'https://localhost:3001'
});

api.interceptors.response.use(async res => {
  return res;
}, async err => {
  console.log(err.response);
  // alert('Error connecting to blockchain. Please refresh your page.')
  return Promise.reject(err);
});

const coinApi = axios.create({
  baseUrl: localServer
})

const tipApi = axios.create({
  baseUrl: localServer
})

export const Api = {
  getUserById: (id) => api.get(`/api/User/${id}`, id),
  getDeliveries: () => api.get(`/api/Deliver/`),
  getDeliveryById: (id) => api.get(`/api/Deliver/${id}`,id),
  getJourneyById: async (id) => {
    let response;

    const mockRes = {
      data: sampleResponse,
      status: 200,
    };

    await api.get(`api/Journey/JOURNEY_${id}`, id)
      .then(res => response = res)
      .catch(err => response = err? mockRes : err.response)
    return response;
  },
};

export const coinApiFunction = {
  getEth: () => coinApi.get(`/coinapi/getEth`),
}

export const tipApiFunction = {
  genContribute: (data) => tipApi.post(`/api/tip/sendGenContributeTx`, data),
  addUser: (data) => tipApi.post(`/api/tip/sendAddUserTx`, data), 
  getUser: (position) => tipApi.get(`/api/tip/getUserTx`, {params:{position}}),
  directContribute: (data) => tipApi.post(`/api/tip/sendDirectContributeTx`, data)
}