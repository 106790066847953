export const mapData = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_JOURNEY":
      const response = action.payload;

      if (!(response.status >= 200 && response.status < 300)) {
        return null;
      }

      const data = response.data;

      const userKeys = ["seedBreeder", "farmer", "elevator", "manufacturer", "retailer"];
      let stageData = [],
        mapData = [];

      for (let key in data) {
        if (key.substr(key.length - 5) === "Stage") {
          stageData.push(data[key]);
        }
      }

      const maxIndex = stageData.length;//num of stages completed thus far
      mapData = { circleData: [], arcData: [] };
      for (let i = 0; i < maxIndex; i++) {
        if (i < maxIndex - 1 ) {
          const stageFrom = stageData[i],
            stageTo = stageData[i + 1];

          const userTypeFrom = userKeys[i],
            userTypeTo = userKeys[i + 1];

          const nameFrom = stageFrom[userTypeFrom].name,
            nameTo = stageTo[userTypeTo].name;

          const locationFrom =
            i === 3
              ? [stageFrom.longitudeArrivedAt, stageFrom.latitudeArrivedAt]
              : [stageFrom.longitude, stageFrom.latitude];

          const locationTo =
            i === 2
              ? [stageTo.longitudeArrivedAt, stageTo.latitudeArrivedAt]
              : [stageTo.longitude, stageTo.latitude];

          const arcObj = {
            inbound: 72633,
            outbound: 74735,
            from: {
              name: nameFrom,
              coordinates: locationFrom
            },
            to: {
              name: nameTo,
              coordinates: locationTo
            }
          };
          mapData.arcData.push(arcObj);
        }
        
        const commodityForm = ["Seeds", "Seeds", "Crop", "Food", "Food"];

        const latitude = i === 3 ? stageData[i].latitudeArrivedAt : stageData[i].latitude;
        const longitude = i === 3 ? stageData[i].longitudeArrivedAt : stageData[i].longitude;
        const name = stageData[i][userKeys[i]].name;
        const address = stageData[i].address;
        const form = commodityForm[i];

        const circleObj = {
          coordinates: [longitude, latitude],
          form,
          name,
          address,
        };
        mapData.circleData.push(circleObj);
      }
        if(data.step >= 13) mapData.arcData.push({
          inbound: 72633,
          outbound: 74735,
          from: {
            name: "Ying Ying Soy Foods Ltd.",
            coordinates: [-79.637052, 43.5115571]
          },
          to: {
            name: "Guelph Marketplace",
            coordinates: [-80.3102079, 43.5344116]
          }
        })
        if(data.step >= 13) mapData.circleData.push({
          coordinates: [-80.3102079, 43.5344116],
          form: "Food",
          name: "Guelph Marketplace",
          address: "Guelph, Ontario",
        })


      if(data.step > 0) mapData.circleData[0].date = data.seedGrowingStage.dateSold;
      if(data.step >= 4) mapData.circleData[1].date = data.deliveries[0].dateDroppedOff;
      if(data.step >= 9) mapData.circleData[2].date = data.elevatorStage.dateStored;
      if(data.step >= 12) mapData.circleData[3].date = data.manufactureFoodStage.dateArrived;
      if(data.step >= 16) mapData.circleData[4].date = data.serveFoodStage.dateArrived;
      
      return mapData;

    default: {
    }
  }
  return state;
};
