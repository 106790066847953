import "./index.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./redux/reducers";
import rootSaga from "./redux/sagas";
import ReactGA from "react-ga";

ReactGA.initialize("UA-138004419-1");
ReactGA.pageview("/");
ReactGA.pageview("/history");
ReactGA.pageview("/map");
ReactGA.pageview("/about");

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =  compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
};
renderApp();

if (module.hot) {
  module.hot.accept();
}
