import React, { Component } from 'react';
import './App.scss';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import asyncComponent from './components/AsyncComponent';

const AsyncLayout = asyncComponent(() => import('./layouts/Layout'));

//Components
const AsyncLogin = asyncComponent(() => import('./components/login/Login'));
const AsyncHistory = asyncComponent(() => import('./components/history/history'));
const AsyncMap = asyncComponent(() => import('./components/map/map'));
const AsyncAbout = asyncComponent(() => import('./components/about/About'));

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#584ba0'
    },
    secondary: {
      main: '#f44336',
    },
  },
});

// const Map = Loadable({
//   loader: () => import('./components/map/map'),
//   loading: () => <div></div>
// })

// const History = Loadable({
//   loader: () => import('./components/history/history'),
//   loading: () => <div></div>
// })

// const Login = Loadable({
//   loader: () => import('./components/login/Login'),
//   loading: () => <div></div>
// })

// const About = Loadable({
//   loader: () => import('./components/about/About'),
//   loading: () => <div></div>
// })

const App = function (props) {
  const path = window.location.pathname;
  let redirected = false;

  if (path.indexOf("/history/") === 0) {
    console.log('es')
    const splitPath = path.split("/");
    props.fetchJourney(splitPath[splitPath.length - 1]);
    redirected = true;
  } else {
    props.fetchJourney('0001');
    redirected = true;
  }

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <div>
          <Switch>
            <AsyncLayout exact path="/about/" component={AsyncAbout} />
            <Route exact path='/' component={AsyncLogin} />
            <AsyncLayout exact path="/map" component={AsyncMap} />
            <AsyncLayout exact path="/history/" component={AsyncHistory} />
            <Route component={() => <AsyncLogin redirected={redirected}/>} />
          </Switch>
        </div>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

const mapDispatchToProps = dispatch => ({
  fetchJourney: id => dispatch({ type: "FETCH_JOURNEY", payload: id })
});

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(App);